<template>
  <div class="home">
    <div class="kids" :class="{ editing }">
      <div class="header">
        <div class="group">
          <button class="is-white" @click="cycleSortMode()" type="button">
            Sorting by {{ sorting }}
          </button>
          <button
            class="is-hidden"
            @click="showShareModalWindow = true"
            type="button"
          >
            Share
          </button>
        </div>
        <div class="group">
          <button v-if="!editing" @click="editing = true" type="button">
            Edit
          </button>
          <button
            v-else
            :class="{ 'is-hidden': kids.length && !editing }"
            @click="editing = !editing"
            type="button"
          >
            Stop editing
          </button>
        </div>
      </div>
      <div v-if="isOldSite" class="card">
        <h1>Woah!</h1>
        <p>
          Heads up! This is the old website. We rebranded to
          <a href="https://uncle.djave.co.uk">uncle.djave.co.uk</a> 🤓
        </p>
        <p>
          If you need to copy all the birthdays over, you can click
          <a :href="url">this link special link</a>
        </p>
      </div>

      <div v-if="tagsArray.length" class="card filter-tags">
        <span
          @click="filterByTag(tag.id)"
          class="tag is-pointer"
          :class="{ 'is-active': tag.id == filteredTag }"
          v-for="(tag, t) in tagsArray"
          :key="'t' + t"
          v-text="tag.value"
        ></span>
      </div>

      <div v-if="!kids.length && !editing" class="card">
        <h1 style="margin-top: 0">Hey!</h1>
        <p>You must be new here.</p>
        <p>
          This is a site I use to keep track of how old all my friends' kids
          are. I can't remember the year someone was born then manually do the
          math.
        </p>
        <p>
          To get started, click
          <span @click="editing = true" style="cursor: pointer">Edit</span>
          below, then add the name, day, month and year of when someone was
          born.
        </p>
        <p @click="showMore = !showMore" style="cursor: pointer">
          <a href="#"
            >Read <span v-text="showMore ? 'less' : 'more'"></span> about this
            app...</a
          >
        </p>
        <p v-if="showMore">
          The app saves (when you click the save button, that is) everything to
          your browser's localStorage. That means that if you are on your phone,
          the data will stay on your phone. The downside is when you come to
          this site from a different computer all the birthday's won't be there,
          but the good side is that it means the data you enter into this site
          is yours, and yours alone!
        </p>
        <p v-if="showMore">
          You can use the share button to generate a link of all the birthdays
          you have. When you give this to someone else and they open it, all
          birthday's will be loaded into their version of this webpage. If
          you're interested in this, click the hidden
          <span @click="showShareModalWindow = true" style="cursor: pointer"
            >share</span
          >
          button at the bottom of the page (after entering some names and
          dates).
        </p>
        <p v-if="showMore">
          If you only want to share some birthdays, you can edit your list of
          names down to just the ones you want to share, then click the share
          button and copy the link, then reload the page without saving anything
          to get your original names back.
        </p>
      </div>

      <div v-for="(kid, k) in kidsToShow" :key="'k' + k">
        <KidCard v-if="!editing" :kid="kid" :tags="tags"></KidCard>
        <div class="card" v-else>
          <div>
            <label :for="'kn' + k">Name</label>
            <input v-model="kid.n" :id="'kn' + k" @keyup.enter="save()" />
          </div>
          <div>
            <label :for="'kb' + k">Birthday (YYYYMMDD)</label>
            <input
              type="date"
              :value="dayjs(kid.b).format('YYYY-MM-DD')"
              @input="($event) => saveBirthDate($event, kid)"
              :id="'kb' + k"
              @keyup.enter="save()"
            />
          </div>
          <div>
            <label :for="'kp' + k">Parents</label>
            <input v-model="kid.p" :id="'kp' + k" @keyup.enter="save()" />
          </div>
          <div class="current-tags">
            <span
              @click="toggleTag(kid, tag.id)"
              class="tag in-edit"
              :class="
                kid.t && kid.t.includes(tag.id) ? 'is-active' : 'is-inactive'
              "
              v-for="(tag, t) in tagsArray"
              :key="'t' + t"
              v-text="tag.value"
            ></span>
          </div>
          <button class="delete-button" type="button" @click="deleteKid(k)">
            &times;
          </button>
        </div>
      </div>

      <div class="addnew" v-if="editing">
        <p>Add a kiddo!</p>
        <form @submit.prevent="add">
          <div>
            <label for="addName">Name</label>
            <input
              type="text"
              v-model="newName"
              placeholder="Jimbob"
              required="required"
              id="addName"
            />
          </div>
          <div>
            <label for="addDay">Day</label>
            <input
              type="number"
              v-model="newDay"
              placeholder="31"
              required="required"
              id="addDay"
            />
          </div>
          <div>
            <label for="addMonth">Month</label>
            <input
              type="number"
              v-model="newMonth"
              placeholder="08"
              required="required"
              id="addMonth"
            />
          </div>
          <div>
            <label for="addYear">Year</label>
            <input
              type="number"
              v-model="newYear"
              placeholder="1991"
              required="required"
              id="addYear"
            />
          </div>
          <div>
            <label for="addParents">Parents</label>
            <input
              type="text"
              v-model="newParents"
              placeholder="Sandra & Brian"
              required="required"
              id="addParents"
            />
          </div>
          <span></span>
          <button type="submit">Save</button>
        </form>
      </div>

      <div v-if="editing" class="card">
        <p>Tags</p>
        <div v-if="editedTag">
          <form @submit.prevent="saveTag" key="saveTagForm">
            <div>
              <label for="editTag">Edit tag</label>
              <input
                :key="'updateTagInput'"
                type="text"
                v-model="editedTag"
                placeholder="NCT"
                required="required"
                id="editTag"
              />
            </div>
            <button type="submit" @click="updateTag">Save</button>

            <button type="button" class="is-danger" @click="deleteTag">
              Delete
            </button>
          </form>
        </div>
        <div v-else>
          <form @submit.prevent="addTag" key="addTagForm">
            <div>
              <label for="addTag">Add new tag</label>
              <input
                :key="'addTagInput'"
                type="text"
                v-model="newTag"
                placeholder="NCT"
                required="required"
                id="addTag"
              />
            </div>
            <button type="submit">Save</button>
          </form>

          <div class="current-tags">
            <span
              @click="editTag(tag.id)"
              class="tag in-edit"
              v-for="(tag, t) in tagsArray"
              :key="'t' + t"
              v-text="tag.value"
            ></span>
          </div>
        </div>
      </div>

      <modal-window
        :open="showImportModalWindow"
        @close="showImportModalWindow = false"
      >
        <template v-slot:title>Woah there!</template>
        <p>
          The link you are visiting is trying to import some birthdays!
          <em>Buuuut</em> you already have some saved. You have a few options at
          this point.
        </p>
        <ul class="modal--options">
          <li>
            <button @click="cancelImport">Cancel</button>
            <span
              >Don't import the birthdays from the link you're trying to
              open.</span
            >
          </li>
          <li>
            <button @click="addImports">Add</button>
            <span
              >Add the birthdays from the link you're opening to your other
              birthdays.</span
            >
          </li>
          <li>
            <button @click="replaceImports">Replace</button>
            <span
              >Delete what you have saved, and replace them with these new
              ones!</span
            >
          </li>
        </ul>
      </modal-window>
      <modal-window
        :open="showShareModalWindow"
        @close="showShareModalWindow = false"
      >
        <template v-slot:title>Share</template>
        <p>
          <label for="shareSubset">
            <input type="checkbox" v-model="shareSubset" id="shareSubset" />
            I only want to share some names...
          </label>
        </p>

        <div v-if="shareSubset">
          <p>Deselect any you don't want:</p>
          <div v-for="(kid, k) in kids" :key="'ks' + k">
            <label :for="'subset-name-' + k">
              <input
                type="checkbox"
                v-model="selectedKids"
                :value="k"
                :id="'subset-name-' + k"
              />
              {{ kid.n }}
            </label>
          </div>
        </div>

        <p>Send this link to a friend!</p>
        <input
          type="text"
          readonly="readonly"
          :value="url"
          class="w-100"
          @click="selectShareUrl"
          ref="shareUrlInput"
        />
        <p>
          <small style="cursor: pointer" @click="showShareModalWindow = false"
            >Close</small
          >
        </p>
      </modal-window>
    </div>
  </div>
</template>

<script>
import ModalWindow from "@/components/ModalWindow.vue";
import KidCard from "@/components/KidCard.vue";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default {
  name: "HomeView",
  components: { ModalWindow, KidCard },
  data() {
    return {
      dayjs,
      sorting: "next birthday",
      showMore: false,
      toImport: [],
      showImportModalWindow: false,
      showShareModalWindow: false,
      editing: false,
      kids: [],
      tags: {},
      newTag: "",
      filteredTag: false,
      editedTag: false,
      editedTagId: false,
      shareSubset: false,
      selectedKids: [],
      newName: "",
      newDay: dayjs().format("DD"),
      newMonth: dayjs().format("MM"),
      newYear: dayjs().format("YYYY"),
      newParents: "",
      storage: localStorage.getItem("kids") + localStorage.getItem("tags"),
    };
  },
  watch: {
    kids() {
      this.selectedKids = [];
      this.kids.forEach((kid, k) => {
        this.selectedKids.push(k);
      });
    },
    editing() {
      this.store();
    },
  },
  computed: {
    kidsToShow() {
      if (!this.filteredTag) {
        return this.kids;
      }
      return this.kids.filter(
        (kid) => kid.t && kid.t.includes(this.filteredTag)
      );
    },
    tagsArray() {
      return Object.keys(this.tags).map((id) => ({
        id,
        value: this.tags[id],
      }));
    },
    isOldSite() {
      let oldSiteUrl = "kids.djave.co.uk";
      // let oldSiteUrl = "localhost";
      return window.location.href.indexOf(oldSiteUrl) >= 0;
    },
    showSave() {
      return (
        (this.storage && this.storage != JSON.stringify(this.kids)) +
          JSON.stringify(this.tags) ||
        (!this.storage && this.kids.length)
      );
    },
    url() {
      let url = "https://uncle.djave.co.uk/";
      if (!this.kids.length) {
        return url;
      }
      let kids = this.kids;
      if (this.shareSubset) {
        kids = kids.filter((kid, k) => {
          return this.selectedKids.indexOf(k) >= 0;
        });
      }
      let ns = kids.map((k) => k.n).join(",");
      let bs = kids.map((k) => k.b).join(",");
      let ps = kids.map((k) => k.p).join(",");
      url = `${url}?n=${encodeURIComponent(ns)}&b=${encodeURIComponent(
        bs
      )}&p=${encodeURIComponent(ps)}`;
      return url;
    },
  },
  mounted() {
    if (localStorage.getItem("tags")) {
      this.tags = JSON.parse(localStorage.getItem("tags"));
    } else {
      localStorage.setItem("tags", "{}");
    }
    if (localStorage.getItem("kids")) {
      this.kids = JSON.parse(localStorage.getItem("kids")).map((kid) =>
        kid.p ? kid : { ...kid, p: "" }
      );
    } else {
      localStorage.setItem("kids", "[]");
    }
    if (localStorage.getItem("sorting")) {
      this.sorting = localStorage.getItem("sorting");
    }
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("n") && urlParams.has("b")) {
      return this.loadFromParams(urlParams);
    }
    this.sortKids();
  },
  methods: {
    saveBirthDate($event, kid) {
      console.log($event.target.value);
      const d = dayjs($event.target.value).format("YYYYMMDD");
      kid.b = d;
      this.store();
    },
    deleteTag() {
      this.kids.forEach((kid) => {
        if (kid.t) {
          kid.t = kid.t.filter((t) => t != this.editedTagId);
        }
      });
      delete this.tags[this.editedTagId];
      this.editedTag = false;
      this.editedTagId = false;
      this.store();
    },
    filterByTag(tagId) {
      if (this.filteredTag == tagId) {
        return (this.filteredTag = false);
      } else {
        return (this.filteredTag = tagId);
      }
    },
    toggleTag(kid, tagId) {
      if (!Object.prototype.hasOwnProperty.call(kid, "t")) {
        return (kid["t"] = [tagId]);
      } else if (kid.t.includes(tagId)) {
        kid.t = kid.t.filter((t) => t != tagId);
      } else {
        kid.t.push(tagId);
      }
      this.store();
    },
    updateTag() {
      this.tags[this.editedTagId] = this.editedTag;
      this.editedTag = false;
      this.editedTagId = false;
      this.store();
    },
    editTag(tagId) {
      this.editedTagId = tagId;
      this.editedTag = this.tags[tagId];
    },
    addTag() {
      let id = 1;
      while (Object.prototype.hasOwnProperty.call(this.tags, id)) {
        id++;
      }
      this.tags[id] = this.newTag;
      this.newTag = "";
      this.store();
    },
    cycleSortMode() {
      if (this.sorting == "next birthday") {
        this.sorting = "oldest";
      } else if (this.sorting == "oldest") {
        this.sorting = "youngest";
      } else {
        this.sorting = "next birthday";
      }
      this.sortKids();
      this.save();
    },
    selectShareUrl() {
      this.$refs.shareUrlInput.select();
    },
    sortKids() {
      if (this.sorting == "next birthday") {
        // Sort by next birthday...
        this.kids = this.kids.sort((a, b) => {
          // Set both birthdays to this year
          let ab = dayjs(a.b, "YYYYMMDD").set("year", dayjs().year());
          let bb = dayjs(b.b, "YYYYMMDD").set("year", dayjs().year());
          if (ab.isBefore(dayjs())) {
            ab = ab.add(1, "year");
          }
          if (bb.isBefore(dayjs())) {
            bb = bb.add(1, "year");
          }
          return ab.format("YYYYMMDD") < bb.format("YYYYMMDD") ? -1 : 1;
        });
      } else if (this.sorting == "youngest") {
        // Sort by youngest
        this.kids = this.kids.sort((a, b) => {
          return a.b < b.b ? 1 : -1;
        });
      } else if (this.sorting == "oldest") {
        this.kids = this.kids.sort((a, b) => {
          return a.b < b.b ? -1 : 1;
        });
      }
    },
    deleteKid(k) {
      this.kids.splice(k, 1);
    },
    add() {
      this.kids.push({
        n: this.newName.replace(",", ""),
        b: [
          this.newYear.toString().padStart(4, "0"),
          this.newMonth.toString().padStart(2, "0"),
          this.newDay.toString().padStart(2, "0"),
        ].join(""),
        p: this.newParents,
      });
      this.newName = "";
      this.newDay = dayjs().format("DD");
      this.newMonth = dayjs().format("MM");
      this.newYear = dayjs().format("YYYY");
      this.newParents = "";
      this.sortKids();
      this.save();
    },
    cancelImport() {
      this.showImportModalWindow = false;
      this.save();
      window.history.pushState({}, null, "/");
    },
    addImports() {
      this.toImport.forEach((k) => {
        this.kids.push(k);
      });
      this.showImportModalWindow = false;
      this.save();
      window.history.pushState({}, null, "/");
    },
    replaceImports() {
      this.kids = this.toImport;
      this.showImportModalWindow = false;
      this.save();
      window.history.pushState({}, null, "/");
    },
    loadFromParams(params) {
      let ns = params.get("n").split(",");
      let bs = params.get("b").split(",");
      if (ns.length != bs.length) {
        console.log("Something was wrong with the url", ns, bs);
        return;
      }
      for (var i = 0; i < ns.length; i++) {
        this.toImport.push({
          n: ns[i].toProperCase(),
          b: bs[i],
        });
      }
      if (this.kids.length) {
        return (this.showImportModalWindow = true);
      }
      this.addImports();
    },
    save() {
      this.editing = false;
      this.sortKids();
      this.store();
    },
    store() {
      localStorage.setItem("kids", JSON.stringify(this.kids));
      localStorage.setItem("tags", JSON.stringify(this.tags));
      this.storage =
        localStorage.getItem("kids") + localStorage.getItem("tags");
    },
    hasParents(k) {
      return Object.prototype.hasOwnProperty.call(k, "p") && k.p;
    },
  },
};
</script>

<style lang="scss">
.home {
  display: grid;
  justify-content: center;
  padding-top: 2em;
}

$editBarHeight: 50px;
.header {
  height: $editBarHeight;
  background: #3c463b;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 30px 0 rgba(#000, 0.3);
  button {
    margin: 0;
    font-size: 12px;
    padding: 5px 10px;
  }
}

.kids {
  padding-top: $editBarHeight + 10px;
}

.kid,
.addnew,
.card {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  // Not so sure about this:
  width: 600px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  margin-bottom: 15px;
  @media (min-width: 660px) {
    margin-bottom: 40px;
    padding: 30px;
    &.filter-tags {
      padding: 10px;
      margin-bottom: 20px;
    }
  }
}

.addnew form {
  display: grid;
  grid-template-rows: repeat(50px, 10);
  grid-gap: 10px;
  @media (min-width: 520px) {
    grid-template-columns: 1fr 1fr;
  }
}

label {
  display: block;
  font-size: 0.7em;
  opacity: 0.6;
  text-align: left;
}
.parents {
  @media (min-width: 520px) {
    grid-column: 1 / 3;
  }
  @media (min-width: 660px) {
    grid-column: 1 / 4;
  }
}

.settings {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 520px) {
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
  }
}

.delete-button {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 0;
}

span.tag {
  cursor: pointer;
  padding: 3px;
  color: #fff;
  background: rgba(0, 168, 255, 0.5);
  border-radius: 2px;
  font-size: 0.8em;
  padding: 2px 8px;
  margin-right: 5px;
  margin-top: 2px;
  display: inline-block;
  &.is-inactive {
    background: rgba(0, 168, 255, 0.2);
  }
  &.is-active {
    background: rgba(0, 168, 255, 1);
  }
  &.is-pointer {
    cursor: pointer;
  }
}
</style>