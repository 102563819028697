<template>
  <div class="modal" v-if="open" @click="$emit('close')">
    <div class="modal--card" @click.stop="">
      <div class="modal--close" @click="$emit('close')"></div>
      <h1><slot name="title"></slot></h1>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWindow",
  props: {
    open: { default: false },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(#000, 0.6);
  padding: 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.modal--card {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.5);
  padding: 30px;
  position: relative;
  padding-top: 15px;
  max-height: 100vh;
  overflow-y: auto;
  width: 600px;
  max-width: 90%;
  h1 {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  p + p {
    margin-top: 20px;
  }
}
.modal--options {
  list-style: none;
  li {
    padding: 20px 0;
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-gap: 10px;
    button {
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
  }
}
.modal--close {
  width: 2em;
  height: 2em;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: #fafafa;
  border-radius: 3px;
  &:before,
  &:after {
    display: block;
    text-align: center;
    content: "I";
    top: 0.3em;
    left: 0;
    width: 2em;
    height: 1.4em;
    position: absolute;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
</style>
