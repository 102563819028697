<template>
  <div class="kid">
    <div v-text="kid.n"></div>
    <div class="kid--bday" v-text="getBday(kid.b)"></div>
    <div class="kid--age" v-text="getTimeSince(kid.b)"></div>
    <div class="kid--parents" v-if="kid.p" v-text="kid.p"></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export default {
  props: ["kid", "tags"],
  methods: {
    getBday(b) {
      // return moment(b).format("Do MMMM");
      return dayjs(b).format("Do MMMM");
    },
    getTimeSince(d) {
      let months = dayjs().diff(d, "months", true);
      if (months < 0) {
        let weeks = Math.floor(dayjs().diff(d, "weeks", true));
        if (weeks >= -13) {
          return "3t (" + Math.abs(weeks) + " weeks)";
        } else if (weeks >= -26) {
          return "2t (" + Math.abs(weeks) + " weeks)";
        } else {
          return "1t (" + Math.abs(weeks) + " weeks)";
        }
        // return "Negative!";
      }
      if (months < 3) {
        return Math.floor(dayjs().diff(d, "weeks", true)) + " weeks!";
      }
      let years = months / 12;
      let output = [
        Math.floor(years),
        Math.floor(years) == 1 ? "year" : "years",
        Math.floor(months % 12),
        Math.floor(months % 12) == 1 ? "month" : "months",
      ];
      return output.join(" ");
    },
  },
};
</script>

<style lang="scss">
.kid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  button {
    position: absolute;
    display: block;
    right: 0;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .editing & {
    padding-right: 60px;
  }
  // @media(min-width: 520px){
  // }
  @media (min-width: 660px) {
    grid-template-columns: 1fr 1fr 1fr;
    & > div {
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.kid--age {
  grid-column: span 2;
  @media (min-width: 660px) {
    grid-column: span 1;
  }
}
.kid--parents {
  display: block;
  font-size: 0.7em;
  opacity: 0.6;
  text-align: left;
}
</style>