<template>
  <div id="app">
    <home-view />
  </div>
</template>

<script>
import HomeView from "@/views/HomeView.vue";
export default {
  components: { "home-view": HomeView },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p + p {
  margin-top: 1em;
}

html,
body {
  height: 100%;
  min-height: 100%;
  background-color: #546b50;
}

body {
  padding-bottom: 40px;
  font-family: "Patrick Hand", cursive;
  font-size: 16px;
  @media (min-width: 660px) {
    font-size: 20px;
  }
  background-size: 250px;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/5961/dino.jpg");
  height: auto;
}

button {
  cursor: pointer;
  font-family: inherit;
  padding: 0.8em 3em 1em;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: #fff;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  background: #00a8ff;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 10px;
  &.is-danger {
    background: #ff3b30;
  }
  // &.is-hidden{
  //   opacity: 0.2;
  //   transition: opacity 0.2s;
  //   &:hover{
  //     opacity: 1;
  //   }
  //   background: #546B50;
  //   border: 2px solid #ffffff;
  // }
}

input {
  padding: 0.5em;
  border-radius: 3px;
  font-family: inherit;
  border: 1px solid #eaeaea;
  font-size: inherit;
  width: 100%;
}
.w-100 {
  width: 100%;
  display: block;
}

.group {
  display: flex;
  gap: 10px;
}
</style>
